

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.reg-sms-form-panel{
border-radius: 50px;
  background-color: #FFF;
  padding-top: 30px;
  width: 430px;
  margin-left: -32px;
  margin-top: 1px;
}
.reg-sms-form-panel .reg-form-steps{
  display: flex;
  
}
#confirm-page-conatiner{
  margin:0px auto;
}
.reg-sms-form-panel .reg-step {

align-items: center;
background-color: #fff;
border: 2px solid #cbe1a2;
border-radius: 20rem;
display: flex;
font-family: Filson Pro Heavy;
font-size: 22px;
height: 40px;
justify-content: center;
width: 40px;
z-index: 1;
margin:0px 3px;
}
.reg-sms-form-panel .reg-step-active {
  background-color: #cbe1a2 !important;
z-index: 2;
}
.reg-sms-form-panel .reg-step-separator {
  height: 0px;
  float: left;
  width: 50px;
  margin-top: 20px;
  background-color: #000;
border: 1.5px solid #000;
height: 1px;
margin-top: 20px;
width: 15px;
}
#sms-page-container-main-form-well-two{
  padding: 0px 20px;
}
#confirm-page-main-form-send-code{
  margin-top: 24px;
}
.reg-container-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-container-confirm .reg-right {
  margin: 100px 0px 100px 0px;
  padding: 30px;
  width: 370px;
  gap: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.reg-tp-txt {
  text-align: center;
  font-family: Sofia Pro Bold;
}
.reg-bekreft-main .reg-tp-txt p {
  font-size: 16px;
  font-family: Sofia Pro Bold;
}
#confirm-page-main-form-error-four{
  display: none;
}
@media only screen and (max-width: 800px) {
  
  .reg-sms-form-panel{
border-radius: 50px;
  background-color: #FFF;
  padding-top: 30px;
  width: 90%;
  margin:0px auto;
}

  .reg-container-confirm .reg-right {
    border: 3px solid black;
    width: 80%;
    position: relative;
  }
  .reg-dsp-stps {
    position: absolute;
    top: -24px;
  }
}

/*Step two form*/
.reg-confirm-form {
  display: grid;
  gap: 25px;
  text-align: left;
  width: 90%;
  margin:0px auto;
}
.reg-verify-block {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
}
.reg-verify-code {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reg-verify-code div {
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
}
#confirm-page-main-form-password-field {
  margin-top: 17px;
  height: 67px;

}
#confirm-page-main-form-cpassword-field{
margin-top: 28px;  
height: 67px;
}
.reg-confirm-input {
  display: flex;
  flex-direction: column;
  height: 56px;
}

.reg-confirm-input input{
  padding: 12px 0px 12px 20px;
  border: 1px solid black;
  border-radius: 50px;
  letter-spacing: 2px;
  margin-top: 3px;
}
.reg-confirm-submit-button button {

   border: none;
  padding: 10px 0px 10px 0px;
  border-radius: 50px;
  width: 80%;
  color: var(--fabel-green);
  background-color:  var(--fabel-dark-purple);
}

.reg-verify-code div input {
  

  width: 60px;
  height: 50px;
  border-radius: 40%;
  border: 2px solid black;
  font-size: 16px;
  text-align: center;
  font-family: "Sofia Pro Bold";
}
#confirm-page-main-form{
  width: 316px;
}
.reg-verify-code-last {
  margin-right:0px !important;
}
.reg-vrfy-send-sms {
  display: flex;
  flex-direction: column;
}
.reg-vrfy-send-sms button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  width: max-content;
  color: #c7e69b;
  text-decoration: underline;
  font-family: Sofia Pro Bold;
}
#alert-mismatch{ display: none; }
.reg-MuiOutlinedInput-notchedOutline{border: none !important}

.reg-invalid-feedback {
  color: red; 
  font-size: 11px;
  font-family: Sofia Pro Regular
}

.reg-inp, #signup_step_two_submit_btn {
  margin-top: 22px;
}
.reg-confirm-submit-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px !important;
  margin-bottom: 25px;
}
@media only screen and (max-width:480px) {
  .reg-verify-code div input {
    margin-right:15px;
    width: 48px;
    height: 40px;
  }
  .reg-confirm-tagline{
    font-family: Sofia Pro Bold;
    font-size: 12pt;
  }
  .reg-confirm-form-label{
    font-family: Sofia Pro Regular;
    font-size: 12pt;
  }
  .reg-confirm-submit-button button {
    font-size: 20pt;
    font-family: Sofia Pro Bold;
     border: none;
    padding: 10px 0px 10px 0px;
    border-radius: 50px;
    width: 80%;
    background-color: #493483;
    color: #c7e69b;
  }
}

.reg-otc {
  position: relative;
  width: 320px;
  margin: 0 auto;
}

.reg-otc fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.reg-otc fieldset div {
  display: flex;
  align-items: center;
}

.reg-otc legend {
  margin: 0 auto 1em;
  color: #5555FF;
}

.reg-confirm-code-text {
  width: .82em;
  line-height: 1;
  font-size: 22px;
  text-align: center;
  appearance: textfield;
  -webkit-appearance: textfield;
  color: #000000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* From: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */
.reg-otc label {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

