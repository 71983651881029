
.reg-mobile-only {
  display: none; /* Hide by default on all screens */
}

.reg-tablet-only {
  display: none; /* Hide by default on all screens */
}

.reg-desktop-only {
  display: none; /* Hide by default on all screens */
}


#product-page-container-row-two-row-five-column{
  margin-left: 50px;

}

.reg-signup-left .reg-prd-ttl {
  text-align: center;
  font-size: 1.5rem;
  color: #e7d0ff;
  font-family: Filson Pro Heavy;

  margin-top: -7px;
  margin-left: 13px;
  font-weight: bold;
}
.reg-signup-left .reg-base-prd-subttl {
  font-family: Filson Pro Regular Italic;
  color: #cbe1a2 !important;
  font-size: 1.17em;
  font-weight: bold;
  margin-left: 14px;
}
.reg-reg-prd-prc {
  margin-left: 14px;
}
.reg-camp-product-list-element {
  margin-top: 6px;
}
.reg-base-product-list-element {
  margin-top: 6px;
}
.reg-part-product-list-element {
  margin-top: 0px;
}


.reg-signup-left .reg-prd-prc > * {
  font-size: 42px;
  color: #cbe1a2;
  font-family: Filson Pro Heavy;
}
.reg-signup-left .reg-prd-prc p {
  margin-top: -5px;
  font-size: 1.25rem;
}

.reg-features{
  margin-top: 16px;
  margin-left: -10px;
}
.reg-not-included-feature{
 margin-left: -10px; 
}
.reg-left-side{
  width: 58%;
  float: left;
}
.reg-middle-side{
width: 10%;
float: left;
}
.reg-right-side{
width: 37%;
float: right;
margin-top: -18px;
}
.reg-terms_and_cond{
  padding:5px 53px
}
.reg-register-inside{
  padding: 0px 13px
}
.reg-base-features{
 margin-top: 14px;
  margin-left: 7px;
  text-align: left;
}
.reg-not-included-feature{
  margin-left: -4px;
}
#product-page-container-description-container{
  font-weight: normal;
}
.reg-part-prd-prc{
  margin-top: -14px;
  margin-left: 18px;
}
.reg-part-prd-prc > * {
  font-size: 42px;
  color: #cbe1a2;
  font-family: Filson Pro Heavy;
}
.reg-part-prd-prc p {
  margin-top: -5px;
  font-size: 1.25rem;
}
.reg-signup-left .reg-part-prd-ttl {
  text-align: center;
  font-size: 1.5rem;
  color: #e7d0ff;
  font-family: Filson Pro Heavy;

  margin-top: -10px;
  margin-left: 18px;
  font-weight: bold;
}
.reg-signup-left .reg-part-prd-subttl {
  font-family: Filson Pro Heavy;
  color: #cbe1a2 !important;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 18px;
}
.reg-part-fabel-hr{
  width: 85%;
}
.reg-signup-left .reg-part-prd-slogan {
  font-size: 20px;
  color: #e7d0ff;
  text-align: center;
  margin: 0.5em;
  margin-left: 29px;
}
.reg-part-prd-slogan p{
  font-family: Sofia Pro Regular;
}
.reg-part-prd-pric-fade{
  opacity: 0.7;
}
.reg-part-features{
  margin-top: 9px;
  margin-left: 6px;
  text-align: left;
}
.reg-product-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px
}
.reg-fontawesome-circle {
  font-size: 10px;
}

.reg-signup-left .reg-camp-prd-ttl {
  text-align: center;
  font-size: 1.5rem;
  color: #e7d0ff;
  font-family: Filson Pro Heavy;

  margin-top: 6px;
  margin-left: 10px;
  font-weight: bold;
}
.reg-signup-left .reg-camp-prd-subttl {
  font-family: Filson Pro Heavy;
  color: #cbe1a2 !important;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 11px;
}
.reg-signup-left .reg-camp-prd-info{
   font-size: 20px;
  color: #e7d0ff;
  text-align: center;
  margin: 0.5em;
  margin-left: 29px;
  width: 80%;
  margin-top: 0px;
}
.reg-camp-prd-info p{
  font-family: Sofia Pro Regular;
}
.reg-camp-features{
  margin-top: 24px;
  margin-left: 6px;
}
/*.product-card {
  width: 100%;
  max-width: var(--product-card-width);
  padding: 20px 0 20px 0;
  min-height: 520px;
  justify-self: center;
  display: grid;
  grid-template-rows: 250px 220px 80px;
  background-color: var(--product-card-bg-color);
  border-radius: 2rem;
}*/
.reg-hide-on-desktop{
    display: none;
  }
.reg-product-select-button:hover{
  cursor: pointer;
  background-color: #afc87f;
}
.reg-button-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-top-div {
  display: grid;
  grid-template-rows: 50px max-content 100px 50px;
  justify-content: center;
}
.reg-product-title {
  color: var(--fabel-green);
  font-size: 28px;
  font-family: Filson Pro Regular;
}
.reg-product-subtitle {
 
  color: var(--fabel-green);
}
.reg-product-subtitle-mobile {
  display: none;
}
.reg-product-price-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reg-product-price {
  color: var(--fabel-green);
  font-family: Filson Pro Heavy;
  font-size: 38px;
}
.reg-product-price-prmonth {
  font-family: Filson Pro Heavy;
  margin-top: -5px;
  color: var(--fabel-green);
  font-size: 28px;
}
.reg-product-streams {
  text-align: center;
  color: var(--fabel-pink);
  font-family: Sofia Pro Regular;
}
.reg-product-feature-div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 38px;
}
.reg-single-feature {
  margin-top: 15px;
}


.reg-feature-dot{
  width: 8%;
}
.reg-feature-line{
  width: 92%;
}
.reg-feature-dot,
.reg-feature-line {
  float: left;
}
.reg-mobile{
  display: none;
}
.reg-fa-circle {
  margin-top: 4px;
  color: var(--fabel-green);
  margin-left: -5px;
}

.reg-bottom-btn {
  padding: 10px;
  width: 90%;
  border-radius: 50px;
  margin:0px auto;
  background-color: var(--fabel-green);
  border: none;
  align-self: center;
}

.reg-product-panel {
 
  margin:0px auto;
  width: 100%;
  
  position: relative;

}
 .reg-base_product-product-sub-panel{
 background-color: var(--product-card-bg-color);
  border-radius: 35px;
  min-height: 520px;
}
.reg-public_campaign_product-product-sub-panel {
  background-color: var(--fabel-dark-purple);
  border-radius: 35px;
  min-height: 520px;
}
.reg-best-buy-tag{
  height: 4rem;
font-family: Filson Pro Heavy;
color: var(--fabel-dark-purple);
font-size: 20px;
margin: -35px 0px;
padding-top: 0.5rem;
border-radius: 18px 18px 0px 0px;
background-color: var(--fabel-green);
text-align: center;
width: auto;
padding-right: 20px;
padding-left: 20px;
}
.reg-feature-button {
  position: absolute;
  bottom: 5px;
  left: 10px;
  width: 80%

}


@media only screen and (max-width: 767px) {
  .reg-left-side{
    width: 100%;
  }
  .reg-right-side {
    width: 86%;
    margin: 0px auto;
    float: none;
  }
  .reg-mobile-only {
    display: block; /* Show on mobile devices */
  }

  .reg-desktop-only, .reg-tablet-only {
    display: none; /* Hide on tablet and desktop devices */
  }
  

  .reg-feature-line{
 margin-top: 3px;
}
.reg-product-panel{
 
  clear: both;
  padding: 20px;
  position: relative;
   max-width: 350px;
   width: 100%;
}
.reg-public_campaign_product-product-sub-panel{
   background-color: var(--fabel-dark-purple);
  margin-bottom: 10px;
  border-radius: 35px;
  min-height:300px;
}
.reg-base_product-product-sub-panel{
   background-color: var(--product-card-bg-color);
  margin-bottom: 10px;
  border-radius: 35px;
  min-height:300px;
}
.reg-best-buy-tag{
  height: 4rem;
font-family: Filson Pro Heavy;
color: var(--fabel-dark-purple);
font-size: 20px;
padding-top: 0.5rem;
border-radius: 18px 18px 0px 0px;
background-color: var(--fabel-green);
text-align: center;
width:auto;
padding-right: 20px;
padding-left: 20px;
}
.reg-feature-button-mob {
  
  width: 100%; margin: 0px auto;

}
  .reg-products-page-title{
    font-family: Filson Pro Heavy;
    font-size: 30pt;
  }
  .reg-products-page-description {
    text-align: center;
    font-family: Sofia Pro Regular;
    color: #6c4ba9;
    font-size: 16pt;
  }
  .reg-product-title {
    color: var(--fabel-green);
    font-size: 24pt;
    font-family: Filson Pro Regular;
  }
  .reg-product-mobilesubtitle{
    color: var(--fabel-green);
    font-size: 14px;
    font-family: Filson Pro Regular Italic;
  }
  .reg-product-streams{
    font-size: 14px;
    font-family: Sofia Pro Regular;
  }
  .reg-product-price{
    font-family: Filson Pro Heavy;
    font-size: 45pt;
  }
  .reg-bottom-btn{
    width: 80%;
    font-size: 20px
  }
  .reg-product-price-prmonth{
    font-family: Filson Pro Heavy;
    font-size: 25pt;
  }
 
  /*.product-card {
    width: 90%;
    max-width: var(--product-card-width);
     margin-top: 20px;
  }*/
  
 
  .reg-home-product-subtitle{
    font-family: Filson Pro Regular Italic !important;
    font-size: 18px !important;
  font-weight:normal !important;
  }
  
  .reg-fm-mobfont-45{
    font-size:45px !important;  
  }
  
  .reg-fm-mobfont-25{
    font-size:25px !important;  
  }
  
  
  .reg-fm-mobfont-16{
    font-size:16px !important;
  }
  
  .reg-fm-mobfont-14{
    font-size:14px !important;
  }
  
  
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .reg-tablet-only {
    display: block; /* Show on tablet devices */
  }

  
  .reg-desktop-only,
  .reg-mobile-only {
    display: none; /* Hide on mobile and desktop devices */
  }

  nav {
    grid-row: 1;
    grid-column: 1;
  }
  main {
    grid-row: 2;
    grid-column: 1;
  }
  footer {
    grid-row: 3;
    grid-column: 1;
  }

  /*.container {
    max-width: max-content !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }*/
  .reg-title-text {
    font-size: 18px;
  }
  .reg-icon-top > div {
    width: auto;
  }
 
  #lytt-sammen-icon-bottom {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reg-bottom1 {
    display: none;
  }
  .reg-disclaimer-prod {
    display: none;
  }
  form .reg-btn-fabel {
    font-size: 20px;
    height: 50px;
  }
}

@media only screen and (max-width: 1400px) {
  .reg-product-selector {
    margin-top: 20px;
    grid-template-columns: repeat(2, 50%);
    gap: 20px;
  }
  /*.product-card {
    min-height: 330px;
    grid-template-rows: 250px 200px 80px;
    width: 70%;
  }*/
}
@media only screen and (max-width: 1000px) {
  /*.product-card {
    max-width: var(--product-card-width);
    width: 80%;
  }*/
  .reg-bottom-btn {
    width: 95%;
  }
}
@media only screen and (max-width: 1240px) {
  
  .reg-product-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /*.product-card {
    max-width: var(--product-card-width);
    width: 70%;
    min-height: 330px;
    grid-template-rows: max-content 80px;
  }*/
  .reg-top-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .reg-product-subtitle{
    display: none;
  }
  .reg-mobile{
    display: block;
  }
  
  .reg-bottom-div {
    display: none;
  }
  .reg-bottom-btn {
    width: 90%;
    font-size: 16px;
  }
}
.reg-productApp {
  background-color: #e7d0ff;
}

.reg-produkter-main {
  max-width: 1900px;
  grid-row: 2;
  grid-column: 2/3;
  padding: 50px 0px 50px 0px;
}
.reg-container-prd-slctr {
  margin: 0 auto;
  width: 90%;
}

.reg-disclaimer-prod {
  margin-top: 30px;
  text-align: center;
  color: #46387f;
}


.reg-icon-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-icon-top > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.reg-products-page-description {
     text-align: center;
  font-family: Sofia Pro Regular;
  color: #6c4ba9;
  font-size: 22px;
  }

#one-book-icon {
  height: 105px;
  width: 150px;
    margin-right: 52px;
  margin-top: 12px;
}
#lytt-sammen-icon {
  height: 127px;
  width: 150px;
  margin-left: 53px;

}
#container-products-row-five{
  margin-top: -20px
}
#lytt-sammen-icon-bottom {
  display: none;
  height: 127px;
  width: 150px;
}
.reg-width65{
  width: 65%;
  margin:0px auto;
}
@media only screen and (min-width: 1024px) {
  .reg-desktop-only {
    display: block; /* Show on desktop devices */
  }

  .reg-mobile-only,
  .reg-tablet-only {
    display: none; /* Hide on mobile and tablet devices */
  }
}

@media only screen and (max-width: 800px) {
  .reg-terms_and_cond {
    padding: 5px 36px;
  }
  #product-page-container-row-two-row-five-column{
  margin-left: 32px;
  width: 84%;
  margin-top: 10px;
}
  #product-page-container-row-one-well{
    padding: 11px;
  margin-top: -22px;
  margin-left: 12px;
  }
  #one-book-icon{
    margin-right: 0px;
    margin-top: 0px;
  }
  nav {
    grid-row: 1;
    grid-column: 1;
  }
  main {
    grid-row: 2;
    grid-column: 1;
  }
  footer {
    grid-row: 3;
    grid-column: 1;
  }

  /*.container {
  max-width: max-content !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  }*/
  .reg-title-text {
  font-size: 18px;
  }
  .reg-icon-top > div {
  width: auto;
  }
 
  #lytt-sammen-icon-bottom {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .reg-bottom1 {
  display: none;
  }
  .reg-disclaimer-prod {
  display: none;
  }
  form .reg-btn-fabel {
  font-size: 20px;
  height: 50px;
  }
}



.reg-fm-top-padding{
	padding-top:20px;
}

.reg-fm-submit-btn{
	    background-color: var(--fabel-dark-purple) !important;
    color:var(--fabel-green) !important;
}



