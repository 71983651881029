

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.reg-container-forgot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-container-forgot .reg-right {
  margin: 100px 0px 100px 0px;
  padding: 30px;
  width: 370px;
  gap: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
#churn-page-form-error-phone-invalid{
  display: none;
}
.reg-tp-txt {
  text-align: center;
  font-family: Sofia Pro Bold;
}
.reg-bekreft-main .reg-tp-txt p {
  font-size: 16px;
  font-family: Sofia Pro Bold;
}
@media only screen and (max-width: 800px) {
  
  .reg-container-forgot .reg-right {
    border: 3px solid black;
    width: 80%;
    position: relative;
  }
  .reg-dsp-stps {
    position: absolute;
    top: -24px;
  }
}


#alert-mismatch{ display: none; }
.MuiOutlinedInput-notchedOutline{border: none !important}

.reg-invalid-feedback {
  color: red; 
  font-size: 11px;
  font-family: Sofia Pro Regular
}

.reg-inp, #signup_step_two_submit_btn {
  margin-top: 22px;
}
