.reg-payment-step {
  display: flex;
  justify-content: center;
  align-items: center;
}
#payment-container-column-one{
  clear:both;
}
.reg-payment-step .reg-right {
  margin: 100px 0px 100px 0px;
  padding: 30px;
  min-height: 450px;
  width: 370px;
  gap: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.reg-betaling-main .reg-tp-txt {
  display: flex;
  justify-content: center;

}
#payment-container{
  margin:0px auto;
}
.reg-betaling-main .reg-tp-txt p {
  font-size: 18px;
  font-family: Sofia Pro Bold;
  font-weight: bold;
}
.reg-betaling-button {
  color: #493483 !important;
  background-color: #c7e69b !important;
}
.reg-payment-form-panel {
  margin:0px auto;
  width: 430px;
  border-radius: 65px;
  background-color: #FFF;
  padding: 10px;
}
.reg-payment-form-panel .reg-form-steps{
  display: flex;
  width: 240px;
}
.reg-payment-form-panel .reg-step {

align-items: center;
background-color: #fff;
border: 2px solid #cbe1a2;
border-radius: 20rem;
display: flex;
font-family: Filson Pro Heavy;
font-size: 22px;
height: 40px;
justify-content: center;
width: 40px;
z-index: 1;
margin:0px 10px;
}
.reg-payment-form-panel .reg-step-active {
  background-color: #cbe1a2 !important;
z-index: 2;
}
.reg-payment-form-panel .reg-step-separator {
  height: 0px;
  float: left;
  width: 50px;
  margin-top: 20px;
  background-color: #000;
border: 1.5px solid #000;
height: 1px;
margin-top: 20px;
width: 15px;
}


.cardElementsWajid > .ElementsApp .InputElement {
  font-family: Sofia Pro Bold !important;
}
@media only screen and (max-width: 480px) {

  .reg-payment-form-panel{
    width: auto;
  }
  .reg-payment-tagline{
    font-family: Sofia Pro Bold;
    font-size: 12pt;
  }
  .reg-payment-label{
    font-family: Sofia Pro Regular;
    font-size: 12pt;
  }
  .ElementsApp, .ElementsApp .InputElement{
    font-family: Sofia Pro Bold !important;
    font-size: 20pt !important;
  }

  .reg-payment-submit-button{
    font-family: Sofia Pro Bold;
    font-size: 20pt;
    border: none;
    padding: 10px 0px 10px 0px;
    border-radius: 50px;
    width: 80%;
    background-color: #493483;
    color: #c7e69b;
  }
  .reg-payment-disclaimer{
    font-family: Sofia Pro Regular;
    font-size: 12pt;
  }
}
@media only screen and (max-width: 800px) {
 
  .reg-payment-step .reg-right {
    border: 3px solid black;
    width: 80%;
    position: relative;
  }
  .reg-dsp-stps {
    position: absolute;
    top: -24px;
    background-color: white;
  }
}

/* Step three from */
.reg-submit-button button {
  color: #493483;
  background-color: #c7e69b;
}
.reg-step-three-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.reg-step-three-form .reg-tp-txt p {
  text-align: center;
}
.reg-payment-div {
  width: 100%;
}
.reg-payment-option {
  border: 1px solid black;
  border-radius: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 70px;
}
.reg-payment-div .reg-drop {
  flex-direction: column;
  margin-top: 10px;
}
.reg-payment-option .reg-radio {
}
.reg-payment-option .reg-radio-right {
  width: 80%;
  display: flex;
  align-items: center;
}
.reg-payment-option .reg-radio-right i {
  margin-right: 20px;
}
.reg-payment-div input[type="radio"] {
  width: 15%;
  height: 15px;
}
.reg-radio {
  margin-right: 8px;
  height: 15px;
  width: 15px;
  border-radius: 20rem;
  border: 2px solid rgb(104, 104, 104);
  background-color: rgb(206, 206, 206);
  cursor: pointer;
  transition: all 0.3s;
}
.reg-check-radio {
  border: 2px solid rgb(255, 255, 255);
  background-color: #1e1e40;
  transition: all 0.3s;
}
.reg-drop-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.reg-drop-content > div {
  width: 100%;
}
.reg-drop-content div p {
  text-align: center;
  line-height: 25px;
}
.reg-drop img {
  width: 100px;
  float: left;
}

.reg-drop-p .reg-drop-input label {
  color: rgba(107, 107, 107, 0.667);
  position: absolute;
  top: -5px;
  left: 10px;
  background-color: white;
  width: 80px;
  text-align: center;
}
.reg-drop-p .reg-drop-split label {
  position: absolute;
  color: rgba(107, 107, 107, 0.667);
  top: -5px;
  left: 10px;
  background-color: white;
  width: 80px;
  text-align: center;
}
.reg-drop-split {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reg-drop-input {
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;
}

.reg-split-left {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
}
.reg-split-right {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
}
#card-element,
#card-number,
#card-expiry,
#card-cvc {
  border-radius: 30px;
  padding: 15px;
  border: 1px solid rgba(50, 50, 93, 0.4);
  background: white;
}
.reg-card-number-div {
  margin: 0px !important; padding: 0px !important; border: medium none !important; display: block !important; 
                    background: transparent none repeat scroll 0% 0% !important; position: relative !important; opacity: 1 !important;
}
.reg-card-number-div iframe{
  border: medium none !important; margin: 0px !important; padding: 0px !important; width: 1px !important; min-width: 100% !important; 
                    overflow: hidden !important; display: block !important; user-select: none !important; transform: translate(0px) !important; 
                    color-scheme: light only !important; height: 19.2px;
}
.reg-card-number-div input{
border: medium none !important; display: block !important; position: absolute !important; height: 1px !important; 
                    top: -1px !important; left: 0px !important; padding: 0px !important; margin: 0px !important; width: 100% !important; 
                    opacity: 0 !important; background: transparent none repeat scroll 0% 0% !important; pointer-events: none !important; 
                    font-size: 16px !important;
}

.reg-expiry-div{
  margin: 0px !important; padding: 0px !important; border: medium none !important; 
                      display: block !important; background: transparent none repeat scroll 0% 0% !important; position: relative !important; 
                      opacity: 1 !important;
}

.reg-expiry-div iframe{
  border: medium none !important; margin: 0px !important; padding: 0px !important; width: 1px !important; min-width: 100% !important; 
                      overflow: hidden !important; display: block !important; user-select: none !important; transform: translate(0px) !important; 
                      color-scheme: light only !important; height: 19.2px;
}
.reg-expiry-div input{
  border: medium none !important; display: block !important; position: absolute !important; height: 1px !important; 
                      top: -1px !important; left: 0px !important; padding: 0px !important; margin: 0px !important; width: 100% !important; 
                      opacity: 0 !important; background: transparent none repeat scroll 0% 0% !important; pointer-events: none !important; 
                      font-size: 16px !important;
}
.reg-cvc-number-div {
  margin: 0px !important; padding: 0px !important; border: medium none !important; 
                    display: block !important; background: transparent none repeat scroll 0% 0% !important; position: relative !important; 
                    opacity: 1 !important;
}

.reg-cvc-number-div iframe {
  border: medium none !important; margin: 0px !important; padding: 0px !important; width: 1px !important; min-width: 100% !important; 
                    overflow: hidden !important; display: block !important; user-select: none !important; transform: translate(0px) !important; 
                    color-scheme: light only !important; height: 19.2px;
}

.reg-cvc-number-div input {
  border: medium none !important; display: block !important; position: absolute !important; height: 1px !important; 
                    top: -1px !important; left: 0px !important; padding: 0px !important; margin: 0px !important; width: 100% !important; 
                    opacity: 0 !important; background: transparent none repeat scroll 0% 0% !important; pointer-events: none !important; 
                    font-size: 16px !important;
}


.reg-stripe_payment_form{
  padding: 13px;
border: 1px solid #000;
border-radius: 30px;
margin: 0px auto;
}
.reg-payment-success-message{
  color: #002B1D;
  font-family: 'Sofia Pro Bold'
}
