footer {
  clear: both;
  grid-row: 3;
  grid-column: 1 / 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.reg-footer {
  padding: 2rem 0px 0px 0px;
  background-color: #002b1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
}

footer div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
}
footer div:nth-child(1) a {
  color: white !important;
}
footer div:nth-child(2) {
  display: flex;
  flex-direction: row;
}
footer div:nth-child(2) a {
  width: auto;
}
footer div:nth-child(2) a img {
  width: 150px;
}


a {
  text-decoration: none;
}