
.reg-churnlogin-main {
  height: 100%;
  max-width: 1070px;
  grid-row: 2;
  grid-column: 2 / 3;
  display: inline-grid;
}


.reg-bd-text {
  font-weight: bold;
}
.reg-ul-text {
  text-decoration: underline;
}

.reg-container-churnlogin {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  margin-top: 30px;
  column-gap: 40px;
  row-gap: 20px;
}



.reg-product-list-element {
  gap: 10px;
  display: flex;
  align-items: flex-start;
}

.reg-product-list-element i{
  margin-top: 7px;
}

.reg-product-list-element > * {
  color: #cbe1a2;
}

.reg-product-list-element .reg-fa-times {
  color: grey;
  font-size: 14px;
}
.reg-x-field p {
  color: grey;
}
.reg-disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-disclaimer p {
  color: #cbe1a2;
  width: 90%;
  font-size: 14px;
  text-align: left;
}
.reg-new_password_link{
  color: #000000;
}
.reg-churn-login-submit-button{
  background-color: var(--fabel-dark-purple) !important;
color: var(--fabel-green) !important;
}


@media only screen and (max-width: 1200px) {
  .reg-container-churnlogin{
    padding: 20px;
  }
  .reg-signup-left .reg-bck-lnk {
    position: relative;
  }
  .reg-prodsel {
    width: 45%;
  }
}
@media only screen and (max-width: 800px) {

  .reg-container-churnlogin {
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
}
@media only screen and (max-width: 600px) {s
  .reg-stp-one-rgt {
    width: 70%;
  }
}
@media only screen and (max-width: 480px) {
  .reg-churnlogin-subtitle{
    font-family: Sofia Pro Regular;
    font-size: 12pt;
  }
  .reg-churnlogin-subtitle-bold{
    font-family: Sofia Pro Bold;
    font-size: 12pt;
  }
 
}
 .reg-churnlogin-label{
    font-family: Sofia Pro Regular;
  }
.reg-churnlogin-main .reg-sub-title{
  border: 1px solid var(--fabel-green);
border-radius: 90px;
padding: 21px;
width: 87%;
}



