@media (min-width: 1023px) {
  .reg-nav {
    
    width: 90%;
    margin: 0px auto;
  }
}
@media (min-width: 1200px) {
  .reg-nav {
    
    width: 1090px;
    margin: 0px auto;
  }
}
.reg-nav a {
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: Sofia Pro Bold;
}
.reg-nav .reg-log-inn {
  border-radius: 20px;
  background-color: #cbe1a2;
  padding: 10px 40px 10px 40px;
  color: #6c4ba9;
  height: 20px;
  line-height: 20px;
   margin-right: 27px;
}
.reg-menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0px auto;
  padding: 0;
  width: 90%;
}
.reg-menu > li {
  margin: 0 1rem;
  overflow: hidden;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-menu-container {
  background-color: var(--fabel-dark-purple);
}
.reg-top-nav {
  
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid rgb(70, 56, 127);
}

#nav-logo {
	max-width: 120px;
   margin-top: 28px;
  margin-left: 15px;
}

@media only screen and (max-width:767px) {
  .reg-nav .reg-log-inn {
  border-radius: 20px;
  background-color: #cbe1a2;
  padding: 10px 40px 10px 40px;
  color: #6c4ba9;
  height: 20px;
  line-height: 22px;
  margin-left: 0px;
}
.reg-logo-left-section{
  float: left;
  width: 25%
}
.reg-mobile-logo{
  width: 50%;
  float: left;
}
  .reg-ham-container{
   /* width: 40px;
}
}
height: 40px;
float: right;
right: -29px;
top: 7px;
display: flex;
position: relative;*/
float: right;
width: 17%;
  }
  #nav-logo {
  max-width: 120px;
   margin-top: -5px;
  margin-left: 0px;
}

  #hidden-lg-row-item-one{
    height: 69px;
  }
  .reg-ham-container img {
    cursor: pointer;
    width: 60px;
    margin-top: 6px;
  margin-left: -12px;
  }
 .reg-top-nav {
  border-bottom:none;
  width: 100%;
  line-height: 70px;
  } 
label.reg-burger-icon{
 display:flex;
  flex-direction:column;
  width:50px;
  cursor:pointer;
  float: right;
}

label span{
  background: #fff;
  border-radius:10px;
  height:7px;
  margin: 7px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}
.reg-menu-container {
  display: flex;
  height: 55px;
  line-height: 55px;
  position: absolute;
  width: 100%;
    margin-top: -5px;

}
/*.menu-items div{
float: left;
margin: 0px 6px;
width: 50%
}*/
#ham-menu-row-item-one, #ham-menu-row-item-two{
  width: 50%;
}
.reg-menu-container .reg-menu-items{
  display: flex;
  margin:0px auto;
  width: 100%
}

}

   