@font-face {
    font-family: fabel_font_FilsonPro;
    src: local("fabel_font_FilsonPro"),
      url("../../fonts/fonts/fonts/FilsonPro/Mostardesign-FilsonProRegular.otf")
        format("opentype");
  }
 
  @font-face {
    font-family: Filson Pro Black;
    src: local("Filson Pro Black"),
      url("../../fonts/fonts/fonts/FilsonPro/Mostardesign\ -\ FilsonProBlack.otf")
        format("opentype");
  }

  @font-face {
    font-family: Filson Pro Heavy;
    src: local("Filson Pro Heavy"),
      url("../../fonts/fonts/fonts/FilsonPro/Mostardesign - FilsonProHeavy.otf")
        format("opentype");
  }
  
    @font-face {
    font-family: Filson Pro Regular;
    src: local("Filson Pro Regular"),
      url("../../fonts/fonts/fonts/FilsonPro/Mostardesign-FilsonProRegular.otf")
        format("opentype");
  }
  

    @font-face {
    font-family: Sofia Pro Bold;
    src: local("Sofia Pro Bold"),
      url("../../fonts/fonts/fonts/SofiaPro/Mostardesign-SofiaPro-Bold.otf")
        format("opentype");
  }

    @font-face {
    font-family: Sofia Pro Regular;
    src: local("Sofia Pro Regular"),
      url("../../fonts/fonts/fonts/SofiaPro/Mostardesign-SofiaProRegular.otf")
        format("opentype");
  }

      @font-face {
    font-family: Filson Pro Regular Italic;
    src: local("Filson Pro Regular Italic"),
      url("../../fonts/fonts/fonts/FilsonPro/Mostardesign - FilsonProRegular-Italic.otf")
        format("opentype");
  }

      @font-face {
    font-family: Sofia Pro Regular Italic;
    src: local("Sofia Pro Regular Italic"),
      url("../../fonts/fonts/fonts/SofiaPro/Mostardesign - SofiaProRegular-Italic.otf")
        format("opentype");
  }
  body, html{
    position: relative;
  scrollbar-width: thin;
  width: 100%;
  }
  a {
  color: #cbe1a2;
  font-family: Sofia Pro Bold;
}
/*a:hover{
  text-decoration: underline;
}*/
p {
  margin: 0 0 10px;
}
.reg-tilbake-link{
  color: #cbe1a2 !important;
}
.reg-tilbake-link:hover{
  text-decoration: underline;
}
.reg-row div{
}
.reg-well {
  border: 0px solid red;
  margin:10px;
}
.reg-filson-pro-bold{font-family: Filson Pro Bold;}
.reg-filson-pro-heavy{font-family: Filson Pro Heavy;}
.reg-filson-pro-reg-italic{font-family: Filson Pro Regular Italic;}
.reg-filson-pro-reg{font-family: Filson Pro Regular;}
.reg-sofia-pro-reg{font-family: Sofia Pro Regular;}
.reg-sofia-pro-bold{font-family: Sofia Pro Bold;}
.reg-sofia-pro-reg-italic{font-family: Sofia Pro Regular Italic;}
 
.reg-font-size-10 {font-size: 10px}
.reg-font-size-12 {font-size: 12px}
.reg-font-size-14 {font-size: 14px}
.reg-font-size-16 {font-size: 16px}
.reg-font-size-18 {font-size: 18px}
.reg-font-size-20 {font-size: 20px}
.reg-font-size-22 {font-size: 22px}
.reg-font-size-24 {font-size: 24px}
.reg-font-size-25 {font-size: 25px}
.reg-font-size-26 {font-size: 26px}
.reg-font-size-28 {font-size: 28px}
.reg-font-size-30 {font-size: 30px}
.reg-font-size-32 {font-size: 32px}
.reg-font-size-34 {font-size: 34px}
.reg-font-size-36 {font-size: 36px}
.reg-font-size-35 {font-size: 35px}
.reg-font-size-38 {font-size: 38px}
.reg-font-size-40 {font-size: 40px}
.reg-font-size-42 {font-size: 42px}
.reg-font-size-44 {font-size: 44px}
.reg-font-size-45 {font-size: 45px}
.reg-font-size-46 {font-size: 46px}
.reg-font-size-46 {font-size: 46px}
.reg-font-size-48 {font-size: 48px}
.reg-font-size-60 {font-size: 60px}

.reg-font-color-green{color: var(--fabel-green);}
.reg-font-color-pink{color: var(--fabel-pink);}
.reg-font-color-purple{color: var(--fabel-purple);}
.reg-font-color-dark-purple{color: var(--fabel-dark-purple);}
.reg-font-color-red{color: red;}


.reg-container-products{
  max-width: 1640px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.reg-clear-both{
  clear: both;
}

.reg-text-left {
  text-align: left;
}
.reg-text-right {
  text-align: right;
}
.reg-text-center {
  text-align: center;
}
.reg-text-justify {
  text-align: justify;
}
.reg-jumbo h1, .reg-jumbo .h1 {
  font-size: 66px;
}
.reg-jumbo h2, .reg-jumbo .h2 {
  font-size: 60px;
}
.reg-jumbo h3, .reg-jumbo .h3 {
  font-size: 54px;

}
.reg-jumbo h4, .reg-jumbo .h4 {
  font-size: 48px;
}




h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
p {
  margin: 0 0 10px;
}
.reg-row{
  margin-right: -15px;
  margin-left: -15px;
}
.reg-col-1, .reg-col-2, .reg-col-3, .reg-col-4, .reg-col-5, .reg-col-6, .reg-col-7, .reg-col-8, .reg-col-9, .reg-col-10, .reg-col-11, .reg-col-12{
  float: left;
} 
.reg-col-1{
  width: 8.33333%;
}
.reg-col-2{
  width: 16.6666%;
}
.reg-col-3{
  width: 25%;
}
.reg-col-4{
  width: 33.3333%;
}
.reg-col-5{
  width: 41.6666%;
}
.reg-col-6{
  width: 50%;
}
.reg-col-7{
  width: 58.3333%;
}
.reg-col-8{
  width: 66.6666%;
}
.reg-col-9{
  width: 75%;
}
.reg-col-10{
  width: 83.3333%;
}
.reg-col-11{
  width: 91.6666%;
}
.reg-col-12{
  width: 100%
}
.reg-primary-color{
  color: var(--fabel-pink);
}
.reg-secondary-color{
  color: #cbe1a2;
}
.reg-tertiary-color{
  color: #46387f;
}


.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: normal;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
}
.reg-form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.reg-form-panel{
  /*border-radius: 65px;
  background-color: #FFF;
  padding: 10px;
  width: auto;*/

  border-radius: 60px;
  background-color: #FFF;
  padding-top: 20px;
  width: 404px;
  margin-left: -6px;
  margin-top: -3px;
}

.reg-form-panel .reg-form-steps{
  display: flex;
  
}
#product-page-container-form-steps-container{
width: max-content;
margin:0px auto;
}
#product-page-container-form-well{
  margin-top: 41px;
}
#product-page-container-main-form-well-two{
  padding: 0px 32px;
}
#product-page-container-main-form-submit{
  padding-bottom: 20px;
  margin-top: -5px;
}
.reg-signup-input-label-mobile{
  padding: 12px 0px; 
}
.reg-signup-input-label-email{
  padding: 0px 0px 12px 0px; 
}
.reg-signup-checkbox{
  transform: scale(1.5);
}
.reg-form-panel .reg-step {

align-items: center;
background-color: #fff;
border: 2px solid #cbe1a2;
border-radius: 20rem;
display: flex;
font-family: Filson Pro Heavy;
font-size: 22px;
height: 40px;
justify-content: center;
width: 40px;
z-index: 1;
margin:0px 3px;
}
.reg-form-panel .reg-step-active {
  background-color: #cbe1a2 !important;
z-index: 2;
}
.reg-form-panel .reg-step-separator {
  height: 0px;
  float: left;
  width: 50px;
  margin-top: 20px;
  background-color: #000;
border: 1.5px solid #000;
height: 1px;
margin-top: 20px;
width: 15px;
}

@media (min-width: 1023px) {
  .reg-container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
  .reg-hidden-lg {
    display: none !important;
  }
}
 
 @media (min-width: 1200px) {
  .reg-container{
  width: 1070px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
  .reg-hidden-lg {
    display: none !important;
  }
}
 

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .reg-container{
    width: 1070px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .reg-col-3 {
    width: 50%;
  }
}
@media only screen and (max-width:767px) {
  /*.green-line-separator{
    height: 1px;
    background-color: var(--fabel-green);
    color: #000;
    border: none;
    margin-bottom: 6px;
  }*/
  .reg-form-panel{
    width: auto;
    margin:0px auto;
  }
  
  .reg-hidden-sm{
    display: none !important;
  }

  .reg-row{
    margin-right: 0px;
    margin-left: 0px;
  }
  .reg-col-1{
  width: 33.33%;
  }
  .reg-col-2{
    width: 66.6666%;
  }
  .reg-col-3, .reg-col-4, .reg-col-5, .reg-col-6, .reg-col-7, .reg-col-8, .reg-col-9, .reg-col-10{
    width: 100%;
  }
  .reg-col-sm-offset-12 {
    margin-left: 100%;
  }
  .reg-col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .reg-col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .reg-col-sm-offset-9 {
    margin-left: 75%;
  }
  .reg-col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .reg-col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .reg-col-sm-offset-6 {
    margin-left: 50%;
  }
  .reg-col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .reg-col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .reg-col-sm-offset-3 {
    margin-left: 25%;
  }
  .reg-col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .reg-col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .reg-col-sm-offset-0 {
    margin-left: 0%;
  }
}









/**********************/

:root {
  --product-card-bg-color: #002b1d;
  --product-card-width: 350px;
  --fabel-green: #cbe1a2;
  --fabel-pink: #e7d0ff;
  --fabel-purple: #6c4ba9;
  --fabel-dark-purple: #46387f;
}
form {
  justify-content: center;
  align-items: center;
}

form a {
  text-decoration: underline;
  color: #cbe1a2;
}
.reg-product_background-dark {
  background-color: #002B1D;
}
.reg-product_background-light {
  background-color: #554387;
}
.reg-primary-color-pallete{
  color: #E0C9FF;
  border-color:#E0C9FF; 
}
.reg-secondary-color-pallete {
  color: #46387f;
  border-color:#46387f; 
}
.reg-tertiary-color-pallete {
  color: #6c4ba9 !important;
  border-color:#46387f; 
}

.reg-error-message{
  color: red;
  padding: 4px;
  text-align: center;

}
.reg-success-message{
  color: green;
  font-size: 14px;
  font-family: Sofia Pro Regular;
  padding: 4px;
  text-align: center;

}
.reg-textalignleft{
  text-align: left;
}
.reg-textalignleft{
  text-align: left;
}
.reg-gap {
  height:20px;
}

.reg-container-stp-one {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  margin-top: 30px;
  column-gap: 40px;
  row-gap: 20px;
}

.reg-stp-one-rgt {
 
  border-radius: 60px;

  background-color: white;
}

.reg-signup-left {
  grid-column: 1/4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 20px;
  width: 100%;
}
.reg-back-link {
  font-family: Sofia Pro Bold;
  width: 90%;
  text-decoration: underline;
  margin-top: 10px;
  color: #cbe1a2;
  position: absolute;
  left: 0px;
  top: -25px;
  text-align: left;
  font-size: 12px;
}

.reg-signup-left .reg-product-title {
  text-align: center;
  font-size: 42px;
  color: #e7d0ff;
  font-family: Filson Pro Heavy;
}
.reg-signup-left .reg-product-price {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reg-signup-left .reg-product-price > * {
  font-size: 42px;
  color: #cbe1a2;
  font-family: Filson Pro Heavy;
}
.reg-signup-left .reg-product-price p {
  margin-top: -5px;
  font-size: 28px;
}

.reg-left-info {
  grid-column: 1/4;
  grid-row: 2/4;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reg-btn-link {
  color: #cbe1a2;
}


.reg-signup-form {
  display: grid;
  gap: 20px;
  min-height: 300px;
  text-align: left;
}

.reg-sms-code span {
  display: flex;
  gap: 6px;
}
.reg-signup-form a {
  text-decoration: underline;
  color: #cbe1a2;
}

.reg-btn-link {
  border: none;
  background-color: transparent;
  text-decoration: underline;
}


.reg-signup-form {
  display: grid;
  gap: 20px;
  min-height: 300px;
  text-align: left;
}
.reg-signup-form .reg-input, .reg-signup-form .reg-checkbox {
  width: 100%;
  margin-top: 20px;
}
.reg-sms-code span {
  display: flex;
  gap: 6px;
}
.reg-signup-form a {
  text-decoration: underline;
  color: #cbe1a2;
}


.reg-tp-txt {
  text-align: left;
  gap: 10px;
  justify-content: left;
}
.reg-btn-link {
  border: none;
  background-color: transparent;
  text-decoration: underline;
}
.reg-input {
  display: flex;
  flex-direction: column;
  height: 56px;
}
/*.reg-input label, .reg-input input, .reg-sms-code span, .reg-sms-code span button {
  font-size: 14px;
}*/
.reg-input input {
  padding: 12px 0px 12px 20px;
  border: 1px solid black;
  border-radius: 50px;
  letter-spacing: 2px;
}
.reg-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.reg-checkbox label {
  width: 90%;
}


.reg-submit-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reg-submit-button button {

   border: none;
  padding: 10px 0px 10px 0px;
  border-radius: 50px;
  width: 80%;
  background-color: var(--fabel-green);
  color:  var(--fabel-dark-purple);
}



.reg-signup-error {
  color: red; 
  font-size: 11px;
}


.reg-dsp-stps {
  width: 180px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}

.reg-dsp-stps .reg-stp {
  border: 2px solid #cbe1a2;
  border-radius: 20rem;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: Filson Pro Heavy;
  font-size: 22px;
  background-color: white;
}
.reg-dsp-stps .reg-stp-actv {
  z-index: 2;
  background-color: #cbe1a2 !important;
}
.reg-dsp-stps .reg-stp-hr {
  margin-top: 20px;
  width: 15px;
  height: 1px;
  background-color: black;
  border: 1.5px solid black;
}



#tags {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reg-fontawesome-times {
  margin-top: 7px;
  color: grey
}


@media screen and (min-width: 480px) {
	
}