:root {
  --product-card-bg-color: #002b1d;
  --product-card-width: 350px;
  --fabel-green: #cbe1a2;
  --fabel-pink: #e7d0ff;
  --fabel-purple: #6c4ba9;
  --fabel-dark-purple: #46387f;
  --fabel-gray-68: #adadad;
}
nav {
  grid-row: 1;
  grid-column: 1 / 5;
  display: flex;
  justify-content: center;
}

.reg-navigation {
  border-bottom: 1px solid var(--fabel-dark-purple);
}
.reg-product-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px
}
.reg-product-wrapper{
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: var(--product-card-width);
  padding: 20px 0 20px 0;
}
.reg-best-buy > div.reg-best-buy-tag {
    height: 4rem;
    font-family: Filson Pro Heavy;
    color: var(--fabel-dark-purple);
    font-size: 20px;
    margin: -35px 0px;
    padding-top: 0.5rem;
    border-radius: 18px 18px 0px 0px;
    background-color: var(--fabel-green);
    text-align: center;
}
.reg-product-card {
  min-height: 520px;
  justify-self: center;
  display: grid;
  grid-template-rows: 220px 220px 80px;
  background-color: var(--product-card-bg-color);
  border-radius: 2rem;
}
.reg-public_campaign_product-product-sub-panel{
  background-color: var(--fabel-dark-purple);
}

.reg-best-buy > .reg-product-card {
  background-color: var(--fabel-dark-purple);
}
.reg-top-div {
  display: grid;
  grid-template-rows: max-content max-content 100px 50px;
  justify-content: center;
}
.reg-product-title {
  color: var(--fabel-pink);
  font-size: 26px;
  font-family: Filson Pro Heavy;
  padding: 1rem 0.1rem 0 0.1rem;
  text-align: center;
}
.reg-product-subtitle {
  width: 90%;
  text-align: center;
  justify-self: center;
  font-family: Filson Pro Regular Italic;
  font-size: 14px;
  color: var(--fabel-green);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.reg-product-subtitle-mobile {
  display: none;
}
.reg-best-buy-price{
    text-align: center;
    color: var(--fabel-green);
    font-family: Sofia Pro Regular;
    font-size: 14px;
    padding: 0 0.25rem 0 0.25rem
}
.reg-product-price-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reg-product-price {
  color: var(--fabel-green);
  font-family: Filson Pro Heavy;
  font-size: 48px;
}
.reg-product-price-prmonth {
  font-family: Filson Pro Heavy;
  margin-top: -5px;
  color: var(--fabel-green);
  font-size: 26px;
}
.reg-product-streams {
  text-align: center;
  color: var(--fabel-pink);
  font-family: Sofia Pro Regular;
}
.reg-bottom-div {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 10px;
}
.reg-product-property {
  color: var(--fabel-pink);
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  font-family: Sofia Pro Regular;
  font-size: 14px;
  gap: 20px;
  text-align: left;
}
.reg-mobile{
  display: none;
}
.reg-fa-circle {
  margin-top: 8px;
  color: var(--fabel-green);
}
.reg-sms-form-panel form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-bottom-btn {
  padding: 10px;
  width: 80%;
  border-radius: 50px;
  font-size: 24px;
  font-family: Sofia Pro Bold;
  color: var(--fabel-purple);
  background-color: var(--fabel-green);
  border: none;
  align-self: center;
}
@media only screen and (max-width: 1300px) {
  .reg-product-selector {
    margin-top: 20px;
    grid-template-columns: repeat(2, 50%);
    gap: 20px;
  }
  .reg-product-wrapper{
    width: 70%;
  }
  .reg-product-card {
    min-height: 330px;
    grid-template-rows: 270px 220px 80px;
  }
}
@media only screen and (max-width: 1000px) {
  .reg-product-wrapper{
    width: 80%;
    max-width: var(--product-card-width);
  }
}
@media only screen and (max-width: 1300px) {
  .reg-navigation {
    border: none !important;
  }
  .reg-product-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .reg-product-wrapper{
    max-width: var(--product-card-width);
    min-width: 300px;
    width: 70%;
  }
  .reg-product-wrapper.reg-best-buy{
    order: -1;
  }
  .reg-product-card {
    min-height: 330px;
    grid-template-rows: max-content 80px;
  }
  .reg-top-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .reg-product-subtitle{
    display: none;
  }
  .reg-mobile{
    display: block;
  }
  .reg-product-subtitle-mobile {
    display: block;
    height: max-content;
    width: 80%;
    text-align: center;
    justify-self: center;
    font-family: Filson Pro Regular Italic;
    font-size: 14px;
    color: var(--fabel-pink);
  }
  .reg-product-price {
    font-size: 45px;
  }
  .reg-product-price-prmonth {
    font-size: 26px;
  }
  .reg-product-streams {
    display: none;
  }
  .reg-disclaimer-prod {
    display: none;
  }
  .reg-bottom-div {
    display: none;
  }
  .reg-bottom-btn {
    width: 70%;
    font-size: 20px;
  }
}

.reg-container-prd-slctr h1,
.reg-container-prd-slctr h2,
.reg-container-prd-slctr h3,
.reg-container-prd-slctr h4 {
  color: var(--fabel-dark-purple);
  font-family: Filson Pro Heavy;
  font-size: 30px;
}
main {
  max-width: 1900px;
  grid-row: 2;
  grid-column: 2/3;
  padding: 50px 0px 50px 0px;
  margin:0px auto;
}
.reg-container-prd-slctr {
  margin: 0 auto;
  width: 90%;
}
form .reg-btn-fabel {
  height: 40px;
  border-radius: 55px;
  font-size: 16px;
  font-family: Sofia Pro Bold;
  width: 80%;
}
.reg-disclaimer-prod {
  margin-top: 30px;
  text-align: center;
  color: #46387f;
}
.reg-fa-solid {
  font-size: 10px;
}
.reg-product-1 form {
  width: 100%;
}
.reg-icon-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-icon-top > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.reg-page-title{
  text-align: center;
}
.reg-title-text {
  text-align: center;
  font-family: Sofia Pro Regular;
  color: #6c4ba9;
  font-size: 22px;
}
#one-book-icon {
  height: 105px;
  width: 150px;
}
#lytt-sammen-icon {
  height: 127px;
  width: 150px;
}
#lytt-sammen-icon-bottom {
  display: none;
  height: 127px;
  width: 150px;
}
@media only screen and (max-width: 800px) {
  .reg-product-subtitle-mobile {
    display: block;
    height: max-content;
    width: 90%;
    text-align: center;
    justify-self: center;
    font-family: Filson Pro Regular Italic;
    font-size: 14px;
    color: var(--fabel-pink);
  }
  .reg-product-small_screenssubtitle-mobile{
    display: block;
    height: max-content;
    width: 90%;
    text-align: center;
    justify-self: center;
    font-family: Filson Pro Regular Italic;
    font-size: 14px;
    color: var(--fabel-green);
  }
  .reg-container-prd-slctr{
    margin-top: -23px;
  }
  nav {
    grid-row: 1;
    grid-column: 1;
  }
  main {
    grid-row: 2;
    grid-column: 1;
  }
  footer {
    grid-row: 3;
    grid-column: 1;
  }

  .reg-container {
    max-width: max-content !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .reg-title-text {
    font-size: 16px;
  }
  .reg-icon-top > div {
    width: auto;
  }
  #lytt-sammen-icon {
    display: none;
  }
  #lytt-sammen-icon-bottom {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reg-bottom1 {
    display: none;
  }
  form .reg-btn-fabel {
    font-size: 20px;
    height: 50px;
  }
}
@media only screen and (max-width: 500px) {
  .reg-product_wrapper{
    width: 90%;
    max-width: var(--product-card-width);
  }
}
