:root {
  --product-card-bg-color: #002b1d;
  --fabel-green: #cbe1a2;
  --fabel-pink: #e7d0ff;
  --fabel-purple: #6c4ba9;
  --fabel-dark-purple: #46387f;
}
.reg-registercomplete .reg-onebook {
  margin-top: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reg-one-book-btn-link {
  font-family: Sofia Pro Bold;
  background-color: #6c4ba9;
  width: 50%;
  padding: 10px;
  border-radius: 20px;
}
.reg-onebook-step {
  text-align: center;
  font-size: 20px;
  font-family: Sofia Pro Regular;
  color: var(--fabel-pink);
  width: 60%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.reg-substep {
  padding: 10px;
}

.reg-onebook-step .reg-substep {
    font-size: 80%
}

.reg-onebook-step a {
  color: var(--fabel-green);
}

.reg-nb {
    font-style: italic;
    font-size: 80%;
}
.reg-registercomplete hr {
border-bottom: 2px solid #cbe1a2;
width: 650px;
}
.reg-registercomplete h1 {
  color: var(--fabel-pink);
  font-family: Filson Pro Heavy;
}
.reg-step-heading-text {
  color: var(--fabel-green);
  font-size: 26px;
  font-family: Filson Pro Bold;
}

.reg-subtitle {
  font-family: Sofia Pro Regular Italic;
  color: var(--fabel-green);
  font-size: 24px;
}


/*Onebook end*/
.reg-regular-complete-page {
  text-align: center;
  margin-top: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reg-bold-font{
    font-family: Sofia Pro Bold;
}
.reg-regular-step {
    width: 70%;
}
.reg-regular-step .reg-substep{
    
    color: var(--fabel-pink);
}

.reg-disclaimer .substep{
    opacity: 0.7;
    font-family: Sofia Pro Regular;
    color: var(--fabel-green);
    width: 60%;
    text-align: center;
}


.reg-app-icon-appstore{
    width: 280px;
  background-color: #FFF;
  line-height: 55px;
  border-radius: 31px;
}
.reg-app-icon-appstore label{
  color: var(--fabel-purple);
  cursor: pointer;
}
.reg-regular-complete-page .reg-app-icon{
    display: flex;
    gap: 20px;
}

.reg-registercomplete .reg-disclaimer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px
}
.reg-disclaimer .reg-substep a{
    color: var(--fabel-green);
}

@media only screen and (max-width:480px) {
  .reg-registercomplete hr {
    width: 90%;
  }
  .reg-registercomplete .reg-subtitle {
    width: 100%
  }
  .reg-complete-page-title, .reg-complete-page-step-heading{
    font-family: Filson Pro Heavy;
    font-size: 25pt;
  }
  .reg-complete-page-subtitle{
    font-size: 18pt;
  }
  .reg-regular-step {
    font-family: Filson Pro Regular;
    font-size: 16pt;
  }
  .reg-regular-step .reg-bold-font {
    font-family: Filson Pro Bold;
    font-size: 16pt;
  }
  .reg-disclaimer .reg-substep{
     font-size: 14pt;
  }
}
