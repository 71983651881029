.reg-home-product-title{
	font-family: Filson Pro Heavy;
	font-size: 42px;
}
.reg-home-price{
		font-family: Filson Pro Heavy;
		font-size: 42px;
	}

	.reg-home-price-per-month{
		font-family: Filson Pro Heavy;
		font-size: 28px;
	}

@media only screen and (max-width: 480px) {
	.reg-home-product-title{
		font-family: Filson Pro Heavy;
		font-size: 30pt;
	}
	.reg-home-product-subtitle{
		font-family: Filson Pro Regular Italic;
		font-size: 18pt;
	}
	.reg-home-price{
		font-family: Filson Pro Heavy;
		font-size: 45pt;
	}
	.reg-home-price-per-month{
		font-family: Filson Pro Heavy;
		font-size: 25pt;
	}
	.reg-product-list-element {
		font-family: Sofia Pro Regular;
		font-size: 16pt;
	}
	
	.reg-home-disclaimer-text{
		font-family: Sofia Pro Regular;
		font-size: 14pt;
	}
	.reg-homepage-discount{
		font-family: Filson Pro Heavy;
		font-size: 45pt;
	}
	.reg-homepage-discount-text{
		font-family: Filson Pro Heavy;
		font-size: 25pt;
	}
	.reg-homepage-submit-button{
		font-family: Sofia Pro Bold;
		font-size: 20pt;	
	}
}